.Modal-Overlay {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    width: 100vw;
    height: 92vh;
    top: 90px;
    left: 0px;
    z-index: 50;
  }

.Modal-Container {
    display: grid;
    grid-template-areas: 
        'modal-title'
        'drop'
        'modal-OR'
        'file-button';
    min-width: 250px;
    max-width: 600px;
    max-height: 500px;
    width: 60%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    z-index: 100;
    gap: 10px;
    border: 1px solid #daddd7;
}

@media (max-height: 1000px) {
    .Modal-Container {
        display: grid;
        grid-template-areas: 
            'modal-title'
            'drop'
            'modal-OR'
            'file-button';
        width: 100%;
        height: 100%;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
        z-index: 100;
        gap: 10px;
        border: 1px solid #daddd7;
    }
}


.Modal-Title {
    grid-area: modal-title;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
}

.Modal-Drag-Container {
    grid-area: drop;
    width: 100%;
    height: 300px;
    border-radius: 5px;
    background-color: rgba(173, 216, 230, 0.46);
    display: flex;
    justify-content: center;
    align-items: center;
}

.Modal-Load-Container {
    grid-area: drop;
    width: 100%;
    height: 300px;
    border-radius: 5px;
    background-color: rgba(173, 216, 230, 0.46);
    display: flex;
    justify-content: center;
    align-items: center;
}

.Modal-File-Loading {
    width: 100px;
}

.Modal-Close-Button {
    width: 200%;
    height: 200%;
    object-fit: fill;
}

.Modal-Choose-File-Button {
    display: flex;
    justify-content: center;
}
.Modal-Choose-File-Button input[type='file']{
    color: transparent;
    width: 106px;
    height: 36px;
    border-radius: 5px;
}
.Modal-Choose-File-Button input::-webkit-file-upload-button{
    border: 1px solid black;
    background-color: white;
    color: black;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 600;
    margin: 0px 10px 5px 0px;
    cursor: pointer;
    font-size: var(--body);
}

.Modal-Choose-File-Button input:hover {
    font-weight: boldest;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);;
    transform: scale3d(0.2);
}

.Modal-OR {
    grid-area: modal-OR;
    display: flex;
    text-align: center;
    justify-content: center;
}

.Modal-Select-Another-File-Button {
    width: 170px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
    color: black;
    padding: 5px 10px;
    font-weight: 600;
    margin: 0px 10px 5px 0px;
    cursor: pointer;
    font-size: var(--body);
    justify-self: center;
}

.Modal-Select-Another-File-Button:hover {
    font-weight: boldest;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);;
    transform: scale3d(0.2);
}