/* Large devices */
@media (min-height: 1001px) {

    .Translate-Form {
        display: grid;
        grid-template-rows: 82vh 5vh;
        grid-template-areas:
            'thread'
            'input';
        border: 0.5px solid #daddd7;
        text-align: left;
        border-radius: 10px;
        padding: 20px;
    }

}

/* Typical phones */
@media (max-height: 1000px) {

    .Translate-Form {
        display: grid;
        grid-template-rows: 77vh 5vh;
        grid-template-areas:
            'thread'
            'input';
        border: 0.5px solid #daddd7;
        text-align: left;
        border-radius: 10px;
        padding: 20px;
    }

}

/* Small devices */
@media (max-height: 700px) {

    .Translate-Form {
        display: grid;
        grid-template-rows: 72vh 5vh;
        grid-template-areas:
            'thread'
            'input';
        border: 0.5px solid #daddd7;
        text-align: left;
        border-radius: 10px;
        padding: 20px;
    }

}

/* Thread Styling */
.Translate-Thread {
    grid-area: thread;
    display: flex;
    /* Reversed so the thread scrolls correctly */
    flex-direction: column-reverse;
    color: black;
    overflow-y: auto;
}

.Translate-Thread::-webkit-scrollbar {
    width: 10px;
}

.Translate-Thread::-webkit-scrollbar-thumb {
    background: #daddd752;
    border-radius: 10px;
}

/* Question Container and Label */
.Translate-Thread-Question-Container {
    display: flex;
    flex-direction: row-reverse;
}

.Translate-Thread-Question {
    margin: 0px 20px 10px 20px;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    background-color: rgb(232, 232, 232);
    line-height: 2.0rem;
}

/* Answer Container and Label */
.Translate-Thread-Answer-Container {
    display: flex;
    flex-direction: row;
}

.Translate-Thread-Answer-Loading {
    width: 50px;
}

/* Style changes based on actioned feedback from an admin */
.Translate-Thread-Answer {
    margin: 0px 20px 10px 20px;
    padding: 10px;
    border-radius: 5px;
    color: white;
    background-color: #00a884;
    line-height: 2.0rem;
    white-space: pre-wrap;
}

/* ==================================== */
/*         Input Container              */
/* - Prompts (input)                    */
/* - Icons (search, delete, etc)        */
/* ==================================== */

.Translate-Input-Container {
    grid-area: input;
    display: grid;
    grid-template-areas:
        'question search delete settings';
    grid-template-columns: 1fr 40px 40px 40px 40px;
    align-items: center;
}

/* Search box */
.Translate-Search {
    grid-area: question;
    height: 40px;
    padding: 10px;
    border: 0.5px solid #daddd7;
    color: #3A3A3A;
    text-align: left;
    border-radius: 10px;
}
.Translate-Search:focus {
    outline: none;
}
.Translate-Search:disabled {
    border: 0.5px solid #daddd752;
    background-color: white;
}

/* Icons (search, delete, etc) */
.Translate-Submit-Icon {
    grid-area: search;
    width: 25px;
    margin-left: 5px;
}
.Translate-Submit-Icon:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.Translate-Delete-Icon {
    grid-area: delete;
    width: 35px;
    margin-right: 5px;
}
.Translate-Delete-Icon:hover {
    cursor: pointer;
    transform: scale(1.05);
}

/* Select a Langauge Button */
.Translate-Settings-Icon {
    grid-area: settings;
    width: 35px;
    margin-right: 5px;
}
.Translate-Settings-Icon:hover {
    cursor: pointer;
    transform: scale(1.05);
}

/* Langauge List Modal & Animation */
.Translate-Settings-Options{
    position: absolute;
    right: 90px;
    bottom: 100px;
    background-color: #141C25;
    padding: 20px;
    width: 260px;
    border-radius: 10px;
    color: white;
    animation: popup .4s;
}
.Translate-Settings-Options label {
    padding-bottom: 5px;
}

@keyframes popup {
    0%{
      transform: scale(.6);
      bottom: 65px;
      right: 35px;
      opacity: .4;
    }
    100%{
      opacity: 1;
      transform: scale(1);
    }
  }
