/* ------------------------------------------------------------ */
/** App Styling                                                **/
 /* ------------------------------------------------------------ */

/* Ensures if you change the box-sizing of an element, everything inside the element will inherit the box-sizing of that element */
*,
*:before,
*:after {
    box-sizing: inherit;
}

/* Large devices */
@media (min-height: 1001px) {
    
    .App-Container{
        display: grid;
        grid-template-rows: 7vh 93vh;
        grid-template-areas:
            'App-Container-Nav'
            'App-Container-Body';
    }

}

/* Typical phones */
@media (max-height: 1000px) {
    
    .App-Container{
        display: grid;
        grid-template-rows: 9vh 91vh;
        grid-template-areas:
            'App-Container-Nav'
            'App-Container-Body';
    }
    
}

/* Small devices */
@media (max-height: 700px) {
    
    .App-Container{
        display: grid;
        grid-template-rows: 11vh 89vh;
        grid-template-areas:
            'App-Container-Nav'
            'App-Container-Body';
    }

}

.App-Container-Nav{
    grid-area: App-Container-Nav;
    display: flex;
    flex-direction: row;
    background-color: #141C25;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
}

.App-Container-Body{
    grid-area: App-Container-Body;
    overflow: auto;
    padding: 1em;
}