/* =============================================== */
/*                  Shared                         */
/* =============================================== */

.ChatSettings-Header{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #141c2568;
    margin-bottom: 10px;
    padding: 10px;
    color: #555555;
}

.ChatSettings-Body{
    padding: 10px;
}

.ChatSettings-Icon{
    cursor: pointer;
    width: 35px;
    height: 35px;
    padding: 1px;
}
.ChatSettings-Icon:hover{
    transform: scale(1.05);
}

.ChatSettings-Icon-Disabled{
    cursor: not-allowed;
    width: 35px;
    height: 35px;
    padding: 1px;
    filter: grayscale(1.0);
}

.ChatSettings-Slider {
    margin-right: 5px;
    max-width: 400px;
    width: 40%;
    -webkit-appearance: none;
    appearance: none;
    height: 8px;
    background: #141c25e5;
    outline: none;
    border-radius: 10px;
}

.ChatSettings-Slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: rgba(64, 119, 218, 1);
    cursor: pointer;
    border-radius: 50px;
}

.ChatSettings-Slider::-webkit-slider-thumb:hover {
    transform: scale(1.1);
}


/* =============================================== */
/*            ContextForm.js                       */
/* =============================================== */

.ChatSettings-Context-Container{
    display: grid;
    grid-template-columns: 1fr 75px;
    height: 200px;
    grid-gap: 10px;
    border: solid .1px #6c757d;
    border-radius: 5px;
    padding: 10px;
}
.ChatSettings-Context{
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    resize: none;
}

.ChatSettings-List {
    margin-top: 20px;

}


/* =============================================== */
/*            Temperature.js                       */
/* =============================================== */

.ChatSettings-Temperature-Container {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 15px;
    margin-left: 15px;
}