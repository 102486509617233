/* Large devices */
@media (min-height: 1001px) {

    .Chat-Form {
        display: grid;
        grid-template-rows: 82vh 5vh;
        grid-template-areas:
            'thread'
            'input';
        border: 0.5px solid #daddd7;
        text-align: left;
        border-radius: 10px;
        padding: 20px;
    }

}

/* Typical phones */
@media (max-height: 1000px) {

    .Chat-Form {
        display: grid;
        grid-template-rows: 77vh 5vh;
        grid-template-areas:
            'thread'
            'input';
        border: 0.5px solid #daddd7;
        text-align: left;
        border-radius: 10px;
        padding: 20px;
    }

}

/* Small devices */
@media (max-height: 700px) {

    .Chat-Form {
        display: grid;
        grid-template-rows: 72vh 5vh;
        grid-template-areas:
            'thread'
            'input';
        border: 0.5px solid #daddd7;
        text-align: left;
        border-radius: 10px;
        padding: 20px;
    }

}

/* Thread Styling */
.Chat-Thread {
    grid-area: thread;
    display: flex;
    /* Reversed so the thread scrolls correctly */
    flex-direction: column-reverse;
    color: black;
    overflow-y: auto;
}

.Chat-Thread::-webkit-scrollbar {
    width: 10px;
}

.Chat-Thread::-webkit-scrollbar-thumb {
    background: #daddd752;
    border-radius: 10px;
}

/* Question Container and Label */
.Chat-Thread-Question-Container {
    display: flex;
    flex-direction: row-reverse;
}

.Chat-Thread-Question {
    margin: 0px 20px 10px 20px;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    background-color: rgb(232, 232, 232);
    line-height: 2.0rem;
}

/* Answer Container and Label */
.Chat-Thread-Answer-Container {
    display: flex;
    flex-direction: row;
}

.Chat-Thread-Answer-Loading {
    width: 50px;
}

/* Style changes based on actioned feedback from an admin */
.Chat-Thread-Answer {
    margin: 0px 20px 10px 20px;
    padding: 10px;
    border-radius: 5px;
    color: white;
    background-color: #00a884;
    line-height: 2.0rem;
    white-space: pre-wrap;
}

/* ==================================== */
/*         Input Container              */
/* - Prompts (input)                    */
/* - Icons (search, delete, etc)        */
/* ==================================== */

.Chat-Input-Container {
    grid-area: input;
    display: grid;
    grid-template-areas:
        'question search file delete settings';
    grid-template-columns: 1fr 40px 40px 40px 40px;
    align-items: center;
}

/* Search box */
.Chat-Search {
    grid-area: question;
    height: 40px;
    padding: 10px;
    border: 0.5px solid #daddd7;
    color: #3A3A3A;
    text-align: left;
    border-radius: 10px;
}
.Chat-Search:focus {
    outline: none;
}
.Chat-Search:disabled {
    border: 0.5px solid #daddd752;
    background-color: white;
}

/* Icons (search, delete, etc) */
.Chat-Submit-Icon {
    grid-area: search;
    width: 25px;
    margin-left: 5px;
}
.Chat-Submit-Icon:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.Chat-UploadFile-Icon {
    grid-area: file;
    width: 35px;
    margin-right: 5px;
}

.Chat-UploadFile-Icon:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.Chat-Delete-Icon {
    grid-area: delete;
    width: 35px;
    margin-right: 5px;
}
.Chat-Delete-Icon:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.Chat-Settings-Icon {
    grid-area: settings;
    width: 35px;
    margin-right: 5px;
}
.Chat-Settings-Icon:hover {
    cursor: pointer;
    animation-name: spin;
    animation-duration: 1.2s;
    animation-delay: 0.1s;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

/* =========================================================== */
/* File upload message */
/* =========================================================== */

.File-Upload-Message-Container {
    display: grid;
    grid-template-columns: 70px 1fr;
    gap: 0px 0px;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    background-color: rgb(232, 232, 232);
    line-height: 2.0rem;
    grid-template-areas:
        "file message";
}

.File-Upload-Message {
    display: flex;
    flex-direction: column;
    grid-area: message;
    margin-left: 10px;
    text-align: left;
}

.File-Upload-Message-File {
    grid-area: file;
    display: flex;
    align-self: center;
    width: 100%;
    height: 100%;
}