/* ------------------------------------------- */
/*   Nav Logo                                  */
/* ------------------------------------------- */


.Navbar-Logo {
    padding: 15px;
    width: 80px;
    margin-right: 10px;
}

.Navbar-Logo img:hover {
    animation-name: spin;
    animation-duration: 1.2s;
    animation-delay: 1s;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

/* ------------------------------------------- */
/*  Nav Items                                  */
/*  - Container for all links                  */
/* ------------------------------------------- */

.Navbar-Items-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: var(--subheading);
    justify-content: space-around;
    max-width: 300px;
}

/* Each link in the nav */
.Navbar-Items-Container-Link {
    font-size: var(--subheading);
    padding: 10px;
    text-decoration: none;
    color: white;
    justify-content: space-around;
}

.Navbar-Items-Container-Link:hover {
    cursor: pointer;
    color: white;
    transform: scale(1.01);
}

.Navbar-Items-Container-Link-Active {
    border-bottom: 3px solid rgba(64, 119, 218, 1);
    transition-delay: .1s;
    cursor: default;
}
.Navbar-Items-Container-Link-Active:hover {
    transform: scale(1);
}

.Navbar-Item-Icon{
    padding-right: 10px;
}