/* Large devices */
@media (min-height: 1001px) {
    
    .Image-Form {
        display: grid;
        grid-template-rows: 83vh 5vh;
        grid-template-areas: 
        'thread'
        'input';
        width: 100%;
        border: 0.5px solid #daddd7;
        text-align: left;
        border-radius: 10px;
        padding: 10px;
    }

}

/* Typical phones */
@media (max-height: 1000px) {
    
    .Image-Form {
        display: grid;
        grid-template-rows: 78vh 5vh;
        grid-template-areas: 
        'thread'
        'input';
        width: 100%;
        border: 0.5px solid #daddd7;
        text-align: left;
        border-radius: 10px;
        padding: 10px;
        gap: 10px
    }
    
}

/* Small devices */
@media (max-height: 700px) {
    
    .Image-Form {
        display: grid;
        grid-template-rows: 74vh 5vh;
        grid-template-areas: 
        'thread'
        'input';
        width: 100%;
        border: 0.5px solid #daddd7;
        text-align: left;
        border-radius: 10px;
        padding: 10px;
        gap: 10px
    }

}

/* Thread Styling */
.Image-Thread {
    grid-area: thread;
    display: flex;
    /* Reversed so the thread scrolls correctly */
    flex-direction: column-reverse;
    color: black;
    overflow-y: auto;
}
.Image-Thread::-webkit-scrollbar {
    width: 10px;
}
.Image-Thread::-webkit-scrollbar-thumb {
    background: #daddd752;
    border-radius: 10px;
}

/* Question Container and Label */
.Image-Thread-Question-Container {
    display: flex;
    flex-direction: row-reverse;
}
.Image-Thread-Question {
    margin: 0px 20px 10px 20px;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    background-color: rgb(232, 232, 232);
    line-height: 2.0rem;
    white-space: pre-wrap;
}

/* Answer Container and Label */
.Image-Thread-Answer-Container {
    display: flex;
    flex-direction: row;
}
.Image-Thread-Answer-Loading {
    width: 50px;
}

/* Style changes based on actioned feedback from an admin */
.Image-Thread-Answer {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    min-width: 100px;
    margin: 0px 20px 10px 20px;
    border-radius: 5px;
    padding: 5px;
    color: white;
    background-color: #00a884;
    line-height: 2.0rem;
    white-space: pre-wrap;
}
.Image-Thread-Answer img {
    border-radius: 20px;
    padding: 10px;
}
.Image-Thread-Answer img:hover {
    transform: scale(1.01);
}
.Image-Thread-Answer-Error {
    margin: 0px 20px 10px 20px;
    padding: 10px;
    border-radius: 5px;
    color: white;
    background-color: #00a884;
    line-height: 2.0rem;
    white-space: pre-wrap;
}

/* ==================================== */
/*         Input Container              */
/* - Prompts (input)                    */
/* - Icons (search, delete, etc)        */
/* ==================================== */

.Image-Input-Container {
    grid-area: input;
    display: grid;
    grid-template-areas:
        'question icons';
    grid-template-columns: 1fr 160px;
    align-items: center;
}

/* Search box */
.Image-Search {
    grid-area: question;
    height: 40px;
    padding: 10px;
    border: 0.5px solid #daddd7;
    color: #3A3A3A;
    text-align: left;
    border-radius: 10px;
}
.Image-Search:focus {
    outline: none;
}
.Image-Search:disabled {
    border: 0.5px solid #daddd752;
    background-color: white;
}

/* Icons (search, delete, etc) */
.Image-Icons{
    display: flex;
    flex-direction: row;
}
.Image-Icons img:first-child{
    margin-left: 5px;
    width: 31px;
}
.Image-Icons img{
    cursor: pointer;
    margin-right: 5px;
    width: 35px;
}
.Image-Icons img:hover{
    transform: scale(1.05);
}