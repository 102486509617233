/* Large devices */
@media (min-height: 1001px) {
    
    .Text-Form {
        display: grid;
        grid-template-rows: 58vh 30vh;
        grid-template-areas: 
        'thread'
        'input';
        width: 100%;
        border: 0.5px solid #daddd7;
        text-align: left;
        border-radius: 10px;
        padding: 10px;
    }

}

/* Typical phones */
@media (max-height: 1000px) {
    
    .Text-Form {
        display: grid;
        grid-template-rows: 54vh 30vh;
        grid-template-areas: 
        'thread'
        'input';
        width: 100%;
        border: 0.5px solid #daddd7;
        text-align: left;
        border-radius: 10px;
        padding: 10px;
    }
    
}

/* Small devices */
@media (max-height: 700px) {
    
    .Text-Form {
        display: grid;
        grid-template-rows: 44vh 36vh;
        grid-template-areas: 
        'thread'
        'input';
        width: 100%;
        border: 0.5px solid #daddd7;
        text-align: left;
        border-radius: 10px;
        padding: 10px;
    }

}

/* Thread Styling */
.Text-Thread {
    grid-area: thread;
    display: flex;
    /* Reversed so the thread scrolls correctly */
    flex-direction: column-reverse;
    color: black;
    overflow-y: auto;
}
.Text-Thread::-webkit-scrollbar {
    width: 10px;
}
.Text-Thread::-webkit-scrollbar-thumb {
    background: #daddd752;
    border-radius: 10px;
}

/* Question Container and Label */
.Text-Thread-Question-Container {
    display: flex;
    flex-direction: row-reverse;
}
.Text-Thread-Question {
    margin: 0px 20px 10px 20px;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    background-color: rgb(232, 232, 232);
    line-height: 2.0rem;
    white-space: pre-wrap;
}

/* Answer Container and Label */
.Text-Thread-Answer-Container {
    display: flex;
    flex-direction: row;
}
.Text-Thread-Answer-Loading {
    width: 50px;
}

/* Style changes based on actioned feedback from an admin */
.Text-Thread-Answer {
    margin: 0px 20px 10px 20px;
    padding: 10px;
    border-radius: 5px;
    color: white;
    background-color: #00a884;
    line-height: 2.0rem;
    white-space: pre-wrap;
}

/* ==================================== */
/*         Input Container              */
/* - Prompts (label & textarea)         */
/* - Modes (Toggles the Prompts)        */
/* - Search icon                        */
/* - Delete icon                        */
/* ==================================== */

.Text-Input-Container {
    grid-area: input;
    display: grid;
    grid-template-columns: 1fr 130px;
    grid-template-rows: 1fr;
    grid-template-areas: 
        'prompt controls';
    margin-top: 10px;
}

/* 'label or input' & 'textarea' */
.Text-Prompt-Container {
    grid-area: prompt;
    padding: 10px;
    width: 100%;
    height: 100%;
    border-top: 0.5px solid #daddd7;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.Text-Prompt-TextArea {
    resize: none;
    text-align: left;
    width: 100%;
    border-bottom: 0.5px solid #daddd7;
    border-top: none;
    border-left: none;
    border-right: none;
    height: 30%;
    font-style: italic;
}
.Text-Prompt-TextArea:focus {
    outline: none;
}
.Text-Prompt-TextArea:disabled {
    border: none;
    background-color: white;
}
.Text-Prompt-Container label {
    width: 100%;
    color: rgba(64, 119, 218, 1);
    font-weight: 500;
}
.Text-Content-TextArea {
    resize: none;
    text-align: left;
    color: #3A3A3A;
    width: 100%;
    height: 100%;
    border: none;
    overflow: auto;
}
.Text-Content-TextArea:focus {
    outline: none;
}
.Text-Content-TextArea:disabled {
    border: 0.5px solid #daddd752;
    background-color: white;
}

/* ==================================== */
/*         Controls Container           */
/* - Modes (Toggles the Prompt type)    */
/* - Search icon                        */
/* - Delete icon                        */
/* ==================================== */

.Text-Controls-Container {
    grid-area: controls;
    display: grid;
    grid-template-rows: 1fr 50px;
    grid-template-areas: 
        'modes'
        'icons';
    border-left: 0.5px solid #daddd7;
    gap: 5px;
}

.Text-Modes {
    grid-area: modes;
    display: flex;
    flex-direction: column;
}

/* large devices */
@media (min-width: 600px) {
    
    .Text-Modes-Tab {
        background-color: white;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 0.5px solid #daddd7;
        padding: 10px;
        text-align: left;
    }

    .Text-Modes-Tab-Active {
        background-color: rgba(64, 119, 218, 1);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        color: white;
        border: 0.5px solid #daddd7;
        padding: 10px;
        text-align: left;
    }

}

/* Small devices */
@media (max-width: 599px) {
    
    .Text-Modes-Tab {
        background-color: white;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 0.5px solid #daddd7;
        text-overflow: ellipsis;
        text-align: left;
    }

    .Text-Modes-Tab-Active {
        background-color: rgba(64, 119, 218, 1);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        color: white;
        border: 0.5px solid #daddd7;
        text-align: left;
    }

}



/* Icons */
.Text-Icons {
    grid-area: icons;
    display: flex;
    justify-content: space-around;
    height: 40px;
}

.Text-Loading-Icon {
    width: 30px;
    margin-left: 5px;
    margin-right: 5px;
}
.Text-Loading-Icon:hover {
    transform: scale(1.05);
}

.Text-Delete-Icon {
    width: 35px;
    margin-right: 5px;
    cursor: pointer;
}

/* ==================================== */
/*              Input boxes             */
/* - Default                            */
/* - Summarization                      */
/* - Classification                     */
/* - Extraction                         */
/* ==================================== */



/* =========================================================== */
/* File uploading classes ~ pageStatus === 'error-other' */
/* =========================================================== */

.Text-FileInput-Container {
    grid-area: thread;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Text-FileInput-Container img{
    width: 15%;
}