/* CSS Rules */

/* CSS Variables */
:root {
  /* Font Sizes */
  --heading: 1.5rem;
  --subheading: 1.25rem;
  --body: 1rem;

  /* Colours */
  --tlogreen: #C1C7BB;
}

/* Rule - Images should never be rendered at a size wider than their containing element using max-inline-size */
img {
  max-inline-size: 100%;
  block-size: auto;
}

/* ============================================== */
/*  Default Page Container                        */
/* ============================================== */

.PageComponent-AccessDenied {
  text-align: center;
  margin-top: 1%;
}

.PageComponent-AccessDenied img {
  max-width: 300px;
}

.PageComponent-Errors-Container{
  text-align: center;
  padding: 1%;
}

.PageComponent-Errors-Container img {
  width: 15%;
}

.PageComponent-Errors-Container p {
  line-height: 1.7;
}

/* ------------------------------------------------------ */
/** Primary Button (Black Fill & White Text)             **/
/*  Purpose: Use this for a call to action button         */
/* ------------------------------------------------------ */

.Primary-Button{
  border: 2.5px solid rgba(64, 119, 218, 1);
  background-color: rgba(64, 119, 218, 1);
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px 0px 5px 0px;
  font-weight: 600;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  font-size: var(--body);
}

.Primary-Button:disabled{
  background-color: rgb(194, 194, 194);
  border: 2.5px solid rgb(184, 184, 184);
  color: white;
}

.Primary-Button:hover{
  transform: scale(1.03);
  cursor: pointer;
}

/* ------------------------------------------------------ */
/** Secondary Button (White Fill & Black Text)           **/
/*  Purpose: Use this secondary action, e.g. cancel       */
/* ------------------------------------------------------ */

.Secondary-Button{
  border: 1px solid black;
  background-color: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  color: black;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 600;
  margin: 5px 10px 5px 0px;
  height: 35px;
  text-align: center;
  cursor: pointer;
  font-size: var(--body);
  display: flex;
  align-items: center;
  justify-items: center;
}

.Secondary-Button:hover{
  transform: scale(1.03);
}

/* ------------------------------------------------------------ */
/** Link Button (Grey Fill & White Text)                       **/
/*  Purpose: Use this for any page link buttons                 */
/* ------------------------------------------------------------ */

.Link-Button {
  border: 2px solid #3C3C3C;
  background-color: #3C3C3C;
  color: white;
  border-radius: 30px;
  padding: 15px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
}

.Link-Button:hover {
  background-color: #535353;
  color: white;
}

/* ------------------------------------------------------------ */
/** Back Button (Icon)                                         **/
/*  Purpose: Use this for users to return to the home page      */
/* ------------------------------------------------------------ */

.Back-Button {
  max-width: 42px;
  max-height: 42px;
  cursor: pointer;
}

/* ------------------------------------------------------ */
/*  Submit Button (Primary Button Style)                  */
/*  Purpose: Use this in forms for the submit button      */
/* ------------------------------------------------------ */

.Submit-Button{
  border: 2.5px solid black;
  background-color: black;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 15px 10px 10px 0px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  font-size: var(--body);
}

.Submit-Button:hover{
  transform: scale(1.03);
}

/* ------------------------------------------------------ */
/*  Form Styling                                          */
/* ------------------------------------------------------ */

.Form {
  min-width: 250px;
  border: 0.5px solid #daddd7;
  color: #3A3A3A;
  text-align: left;
  border-radius: 3px;
  padding: 40px;
  font-weight: 600;
}

.Form-Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: black;
  font-size: var(--heading);
}

.Form.Group {
  margin: 15px 10px;
  padding: 1.5em;
}

/* ----- Field Styling ----- */

.Label-Field-Title{
  margin: 25px 0px 0px 0px;
  font-size: var(--body);
}

.Label-Field-Value{
  font-size: var(--body);
  color: #929292;
}


/* ------------------------------------------------ */
/*  Table Styling                                   */
/* ------------------------------------------------ */

.Data-Table-Container{
  background-color: white;
  border: 1px solid #B9B9B9;
  margin: 10px 0px;
  border-collapse: collapse;
  line-height: 1.6;
  width: 100%;
}
  
.Data-Table-Container tr {
  border: 1px solid #B9B9B9;
  text-align: left;
}
  
.Data-Table-Container tr:first-child {
  border-bottom: none;
}

.Data-Table-Container th {
  background-color: #141c25e5;;
  font-weight: bold;
  padding: 10px 10px;
  color: white;
}

.Data-Table-Container th:first-child {
  padding-left: 30px;
}

.Data-Table-Container th:last-child {
  padding-right: 30px;
}

.Data-Table-Container td {
  padding: 20px 10px;
}

.Data-Table-Container td:first-child {
  padding-left: 30px;
}

.Data-Table-Container td:last-child {
  padding-right: 30px;
}
  
/* ------------------------------------------------------------ */
/** CSS Media Queries (Mobile)                                 **/
/* ------------------------------------------------------------ */

@media (max-width: 375px) {

  .Back-Button {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
 
}

/* ------------------------------------------------------------ */
/*  Custom Scroll bBar                                                 */
/*  https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp    */
/* ------------------------------------------------------------ */


.Custom-Scroll-Bar {
  overflow-y: auto;
}
  
.Custom-Scroll-Bar::-webkit-scrollbar {
  width: 10px;
}

.Custom-Scroll-Bar::-webkit-scrollbar-track {
  background: #f3f3f3;
}

.Custom-Scroll-Bar::-webkit-scrollbar-thumb {
  background: #0c6bde68;
  border-radius: 10px;
}
